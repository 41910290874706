.flex{
  display: flex;
}
.space-between{
  justify-content: space-between;
}
.align-center{
  align-items: center;
}
.background-white{
  background-color: white;
}
.margin-none{
  margin:0;
}
.padding-none{
  padding:0;
}
.margin-x-1{
  margin-left: 1rem;margin-right: 1rem;
}
.color-green{
  color:#26C24C;
}
.color-grey{
  color:grey;
}
.font-3{
  font-size:1.5rem
}
.main-scr{
  margin-left: 300px;
}
.profile-comp-center svg{
  margin-left: 3rem;
}
.navbar{
  padding:0.5rem 2rem;
  /* width: 100%; */
}
.navbar h1{
  font-size: 2.5rem;
  color: #23304C;
  font-weight: 400;
}
.navbar-right{
  font-size: 1.25rem;
}
.navbar-right input{
  width: 10vw;
  font-size: 2rem;
}
.navbar-right input:hover{
  border-bottom: 1px solid black;
  background-color: rgb(240, 235, 235);
  transition: 0.2s background-color linear;
}
.navbar-right button{
  border: none;
  background-color: #2B83EA;
  padding:0.25rem 0.75rem;
  border-radius: 0.75rem;
  color: white;
}

.sidebar{
  position: fixed;
  padding:0rem 1.5rem;
  display: flex;
  flex-direction: column;
  width: 300px;
  height: 100%;
  background-color: #23304C;
  overflow-y:scroll;
  

}
.sidebar::-webkit-scrollbar{
  width: 10px;
}
.sidebar::-webkit-scrollbar-track{
  background-color:#23304C;
}
.sidebar::-webkit-scrollbar-thumb{
  background-image: linear-gradient(rgba(128, 128, 128,0.2), rgba(255, 255, 255,0.2));
  border-radius: 9999px;
}
.sidebar h1{
  padding: 1rem 1rem;
  font-size: 2.5rem;
  font-weight: 600;
  
  font-family: 'Times New Roman', Times, serif;
  color: white;
}
.sidebar ul{
  display: flex;
  flex-direction: column;
  
  list-style: none;
}
.sidebar li{
  color:#A8B0BC;
  margin:0.75rem 0;
}
.sidebar li:hover{
  color: #53B9EA;
}

.sidebar-close{
  transform: translateX(-100%);
  transition: 0.5s;
}
.sidebar-open{
  transform: translateX(0%);
  transition: 0.4s;
}
.times,.bars{
  border:none;
  display: none;
  margin-right:1rem;
  font-size: 1.5rem

}
.times{
  padding: 0;
  margin: 0;
  background: transparent;
  color: red;
}

.profile-comp{
  margin:2rem;
  background-color: white;
  line-height: 1.5;
}
.profile-comp-center{
  margin:2rem; 
  padding-top:2rem;
}
.profile-comp-center div{
  width: 30%;
  margin:2rem 0rem;
}
.process p{
  font-size: .85  rem;
  margin-top: 1rem;
  color: #4C5565D9;
}
.process button{
  border: none;
  outline: none;
  padding:0.5rem 1rem;
  margin-top: 1rem;
  border-radius: 0.5rem;
  color:white;
  background-color: #2B83EA;
}
.process button:hover{
  transition: 0.2s all ease-out;
  background-color: #115eb6;

}

.home-com-2{
  background-color: white;
  padding: 1rem;
  margin:2rem;
}
.home-com-2 button {
  border:none;
  color: #115eb6;
  padding: 0rem 1rem;
  margin-left: 1rem;
  border-left: 1px solid black;
  background-color: transparent;
}
.home-com-2 select,.date{
  border: 1px solid rgb(146, 146, 146);
  font-size: 0.85rem;
  padding:0.5rem 1rem;
}

.home-com-3{
  margin:2rem;
}
.home-com-3 article{
  width: 30%;
  padding:1rem 3rem;
  background-color: white;
}
.home-com-3 header{
  margin-bottom: 1rem;
}
.home-com-3 p{
  font-weight: bold ;
}
.filter-container{
  padding:1rem 0;
  border-top: 1px solid #00000040;
  border-bottom: 1px solid #00000040;
  margin-bottom: 2rem;
}
.form-group input{
  padding: 0.5rem 1rem;
  border:1px solid #A8B0BC;
  font-size: 1.05rem;
}

.form-group select{
  width: 100%;
}
.form-group label{
  display: block;
}
.form-group input:focus,.form-group input:hover{
  transition: 0.2s linear;
  background-color: rgb(218, 212, 212);
}
.transaction-button-container{
  width: 50%;
}
.transaction-button-container button{
  border-bottom: 2px solid transparent;
  font-size: 1.05rem;
  padding-bottom: 1rem;
  border:none;
  background-color: transparent;
}
.transaction-button-container button:nth-child(1){
  color: #115eb6 ;
  border-bottom: 2px solid #115eb6;
}
.transactions{
  margin:2rem;
  padding: 1rem;
  border: 1px solid #00000040;
  border-radius: 1rem;
  background-color: white;
}

.transactions-box div{
  padding:0.75rem 1rem;
  border-top: 1px solid #00000040;
  border-bottom: 1px solid #00000040;
}
.transactions-box p{
  text-align: center;
  width: 10%;
}

.background-f9{
  background-color: #f9f9f9;
}
.color-blue{
  color:#115eb6;
}

.failed,.captured{
  color: white;
  text-align: center;
  padding:0.25rem 0.75rem;
  border-radius: 1rem;
}
.failed{
  background: #EA534C;

}
.captured{
  background: #26C24C;
}
@media (max-width:1024px){
  .hide-1024{
    display: none;
  }
  .transactions{
    margin:1rem
  }
}
@media (max-width:900px){
  .hide-900{
    display: none;
  }
  .navbar h1{
    font-size: 1.5rem;
  }
.margin-x-1{
  margin-left: 0.5rem;margin-right: 0.5rem;
}
.form-group input{
  width: 50%;
}
}

@media (max-width:765px){
  .hide-765{
    display: none;
  }
  .main-scr{
    transition-delay: 0.5s;
    transition: 0.5s;
    margin-left: 0;  
  }
  .times,.bars{
    display: inline;
  }
  .hide-md{
    display: none;
  }
.navbar h1{
  font-size: 1.5rem;
}
}

@media(max-width:635px){
  .hide-635{
    display: none;
  }
  .process{
    text-align: center;
    flex-direction: column;
  }
  .process svg{
    margin:0;
  }
  .process div{
    width:100%;
  }
.home-com-2{
  flex-direction: column;

  }
  .home-com-3 article{
    padding: 1rem;
  }
  .filter-form{
    flex-direction: column;
  }
  .form-group{
    margin-top: 1rem;
  }
  .form-group input{
    width:100%;
  }
  .single-transaction{
    text-align: center;
    flex-direction: column;
  }
  .single-transaction p{
    display: inline;
  }
  .transactions-box p{
    width: 100%;
  }
}